import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Urls} from "../constants/urls";

interface ReservationFormInterface {
    restaurantId: string
    guests: string
    date?: string
    time?: string | undefined
    bookingPolicy?: string
    darkMode?: boolean
    onSuccess: () => void;
}

const ReservationForm = ({
                             restaurantId,
                             guests,
                             date,
                             time,
                             bookingPolicy,
                             onSuccess,
                             darkMode
                         }: ReservationFormInterface) => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    const [isPending, setIsPending] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [isError, setIsError] = useState(false)

    const confirmBooking = () => {
        const formData = {
            restaurant_id: restaurantId,
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            message: message,
            persons: guests,
            date: date,
            time: time
        }


        async function getRequest() {
            setIsPending(true)
            const url = `${Urls.widgetAPI.confirmBooking}`
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                setIsError(false);
                onSuccess();
            } else {
                setIsError(true)
            }
        }

        getRequest().then(() => {
            setTimeout(() => {
                setIsPending(false)
                setIsDone(true)
            }, 1000)
        }).catch(err => {
            setIsPending(false)
            setIsDone(true)
            setIsError(true)
        });
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        confirmBooking()
    }

    const {t, i18n} = useTranslation()

    const dynamicLabelStyle = {
        color: darkMode ? "white" : undefined
    }

    const dynamicInputStyle = {
        // background: darkMode ? "#343a40" : undefined,
        // borderColor: darkMode ? "#343a40" : undefined,
        // color: darkMode ? "#fff" : undefined
    }

    const dynamicConfirmButtonStyle = {
        background: darkMode ? "rgb(1,198,108)" : undefined
    }

    const inputClass = `form-control input-x ${darkMode ? 'input-x-dark' : ''}`

    return <form onSubmit={handleSubmit}>

        {/*<div className="form-group mb-1">*/}
        {/*    <label htmlFor="name" className="mb-0" style={dynamicLabelStyle}><Trans t={t}*/}
        {/*                                                                            i18nKey="bookingWidget.form.fullName"/></label>*/}
        {/*    <input disabled={isDone} required type="text" className={inputClass} style={dynamicInputStyle}*/}
        {/*        // placeholder={i18n.t('bookingWidget.form.firstName')}*/}
        {/*           id="name" value={fullName} onChange={e => setFulltName(e.target.value)}/>*/}
        {/*</div>*/}

        <div className="form-group mb-1">
            <label htmlFor="name" className="mb-0" style={dynamicLabelStyle}><Trans t={t}
                                                                                    i18nKey="bookingWidget.form.firstName"/></label>
            <input disabled={isDone} required type="text" className={inputClass} style={dynamicInputStyle}
                // placeholder={i18n.t('bookingWidget.form.firstName')}
                   id="name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="last-name" className="mb-0" style={dynamicLabelStyle}><Trans t={t}
                                                                                         i18nKey="bookingWidget.form.lastName"/></label>
            <input disabled={isDone} required type="text" className={inputClass} style={dynamicInputStyle}
                // placeholder={i18n.t('bookingWidget.form.lastName')}
                   id="last-name" value={lastName} onChange={e => setLastName(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="email" className="mb-0" style={dynamicLabelStyle}><Trans t={t}
                                                                                     i18nKey="bookingWidget.form.email"/></label>
            <input disabled={isDone} required type="email" className={inputClass} style={dynamicInputStyle}
                   id="email"
                // placeholder="email@example.com"
                   value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
        <div className="form-group mb-1">
            <label htmlFor="phone" className="mb-0" style={dynamicLabelStyle}><Trans t={t}
                                                                                     i18nKey="bookingWidget.form.phone"/></label>
            <input disabled={isDone} required type="phone" className={inputClass} style={dynamicInputStyle}
                   id="phone"
                // placeholder="Phone number"
                   value={phone} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className="form-group mb-3">
            <label htmlFor="message" className="mb-0" style={dynamicLabelStyle}><Trans t={t}
                                                                                       i18nKey="bookingWidget.form.message"/></label>
            <textarea disabled={isDone} className={inputClass} style={dynamicInputStyle} id="message" rows={2}
                // placeholder={i18n.t('bookingWidget.form.message')}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      required={false}
            />
        </div>
        {bookingPolicy &&
        <div className={"mb-3"}>

            <div style={{
                backgroundColor: darkMode ? '#343a40' : "#f8f9fa",
                display: 'flex',
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 12,
                paddingRight: 12,
                borderRadius: 4,
                justifyContent: 'flex-start'
            }}>
                <p style={{color: darkMode ? "white" : '#343a40'}}
                   className={"booking-policy-text"}>
                    <b><Trans t={t} i18nKey="bookingWidget.form.bookingPolicyTitle"/></b><br/>
                    {bookingPolicy}
                </p>
            </div>
            {/*<SelectorButton text={bookingPolicy}/>*/}
        </div>
        }
        {!isDone && !isPending &&
        <button type="submit" className="btn btn-md btn-dark border-min p-1 pl-3 pr-3 btn-block booking-button"
                style={dynamicConfirmButtonStyle}>
            {i18n.t('bookingWidget.confirmReservation')}
        </button>
        }
        {!isDone && isPending &&
        <button type="submit" disabled
                className="btn btn-md btn-dark border-min p-1 pl-3 pr-3 btn-block booking-button">
            {i18n.t('bookingWidget.processing')}
        </button>
        }
        {isDone && !isError && <>
            <p style={dynamicLabelStyle}><b>{i18n.t('bookingWidget.isConfirmedTitle')}</b></p>
            <p style={dynamicLabelStyle}>{i18n.t('bookingWidget.isConfirmedText')}</p>
        </>
        }
        {isDone && isError && <>
            <p style={dynamicLabelStyle}><b>{i18n.t('bookingWidget.isNotConfirmedTitle')}</b></p>
            <p style={dynamicLabelStyle}>{i18n.t('bookingWidget.isNotConfirmedText')}</p>
        </>}
    </form>
}

export default ReservationForm
