import React from 'react';
import '../App.css';
import HeroImage from '../assets/img/hero/hero4.jpg';

import UnDraw1Image from '../assets/img/service/undraw_special_event_4aj8.svg';
import UnDraw2Image from '../assets/img/service/undraw_Calendar_re_ki49.svg';
import UnDraw3Image from '../assets/img/service/undraw_web_devices_ad58.svg';

import GettingStartedImage from "../assets/img/getting-started/resturant-4.jpg";
import InYourPockerImage from "../assets/img/about/iphone10.jpg";
import GetMoreCustomersImage from "../assets/img/about/iphone-users-app.jpg";

import MessageForm from "../components/MessageForm";
import PricingPlan from "../components/PricingPlan";
import {Trans, useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";

import {IPadVideoUrls} from "../constants/urls";
import VideoFrame from "../components/VideoFrame";


function Home() {
    const {t, i18n} = useTranslation()

    return (
        <div className="Home">
            <section id="home" className="hero-section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-1 order-md-last order-sm-last order-last">
                            <div className="hero-content">
                                <h1 className="wow fadeInUp" data-wow-delay=".2s">
                                    <Trans t={t} i18nKey="hero.title">
                                        Managing reservations<span> has never been so easy</span>
                                    </Trans>
                                </h1>
                                <p className="wow fadeInUp" data-wow-delay=".4s">
                                    <Trans t={t} i18nKey="hero.text1">
                                        With Reetab you can manage reservations in your restaurant wherever you are.
                                        <b> Get new customers</b> by giving them an option to book a table in your
                                        restaurant
                                        with our
                                        app.
                                    </Trans>
                                </p>
                                <p><b><Trans t={t} i18nKey="hero.text2">Start managing your reservations with Reetab
                                    today.</Trans></b></p>
                                <p className="wow fadeInUp" data-wow-delay=".6s">
                                </p>
                                <HashLink to={'/#why'} className="main-btn btn-hover wow fadeInUp"><Trans t={t}
                                                                                                          i18nKey="hero.button"/></HashLink>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-2 order-md-first order-sm-first order-first">
                            <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                                <img src={HeroImage} alt="" width="100%"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="why" className="service-section pt-80">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center mb-90">
                                <h1 className="wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                         i18nKey="whyReetabSection.title"/>
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="single-service wow fadeInUp" data-wow-delay=".4s">
                                <div className="icon-big">
                                    <img src={UnDraw1Image} alt=""/>
                                </div>
                                <div className="content">
                                    <h3><Trans t={t} i18nKey="whyReetabSection.getMoreCustomersTitle"/></h3>
                                    <p>
                                        <Trans t={t} i18nKey="whyReetabSection.getMoreCustomersText">
                                            Customers are more likely to book a table in your restaurant when they can
                                            check
                                            availability right away, <b>without making a call.</b>
                                        </Trans></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="single-service wow fadeInUp" data-wow-delay=".2s">
                                <div className="icon-big">
                                    <img src={UnDraw2Image} alt=""/>
                                </div>
                                <div className="content">
                                    <h3><Trans t={t} i18nKey="whyReetabSection.manageReservationsTitle"/></h3>
                                    <p>
                                        <Trans t={t} i18nKey="whyReetabSection.manageReservationsText">
                                            Organize your restaurant with reservations management system. <b>Have a
                                            clear
                                            overview</b> of
                                            what's
                                            going on in your restaurant.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <div className="single-service wow fadeInUp" data-wow-delay=".6s">
                                <div className="icon-big">
                                    <img src={UnDraw3Image} alt=""/>
                                </div>
                                <div className="content">
                                    <h3><Trans t={t} i18nKey="whyReetabSection.WhereverYouAreTitle"/></h3>
                                    <p>
                                        <Trans t={t} i18nKey="whyReetabSection.WhereverYouAreText">
                                            <b>You and your employees</b> can view, edit and add reservations using your
                                            computer, tablet
                                            or
                                            any iOS or Android smartphone.
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="getting-started" className="about-section pt-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-img wow fadeInUp" data-wow-delay=".5s">
                                <img src={GettingStartedImage} alt="" className="image-shadow"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-title">
                                    <span className="wow fadeInUp" data-wow-delay=".2s"><Trans t={t}
                                                                                               i18nKey="gettingStarted.subtitle"/></span>
                                    <h2 className="mb-35 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="gettingStarted.title"/>
                                    </h2>
                                    <p className="wow fadeInUp" data-wow-delay=".6s">
                                        <Trans t={t} i18nKey="gettingStarted.text">
                                            To get started with Reetab, all you have to do is <b>sign up</b> with your
                                            restaurant, set <b>open hours,</b> add your <b>tables</b> and you
                                            are <b>done.</b> Customers can now book a table in your restaurant using our
                                            app. And you can <b>start managing your reservations.</b>
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="anywhere" className="delivery-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <span className="wow fadeInUp"
                                          data-wow-delay=".2s"><Trans t={t} i18nKey="inYourPocket.subtitle"/></span>
                                    <h2 className="mb-35 wow fadeInUp" data-wow-delay=".4s">
                                        <Trans t={t} i18nKey="inYourPocket.title"/>
                                    </h2>
                                    <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                                        <Trans t={t} i18nKey="inYourPocket.text">
                                            You can manage your reservations from
                                            your <b>iOS</b> or <b>Android</b> smartphone,
                                            iPad or any Android tablet. Our app is also
                                            available in <b>web browsers</b> so you can manage your reservations on
                                            laptop or PC as well.
                                        </Trans>
                                    </p>

                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-1"/>*/}
                        <div className="col-lg-8 order-first order-lg-last">
                            <div className="delivery-img wow fadeInUp" data-wow-delay=".5s" style={{paddingLeft: 0}}>
                                <img src={InYourPockerImage} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="get-more-customers" className="about-section pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-img wow fadeInUp" data-wow-delay=".5s">
                                <img src={GetMoreCustomersImage} alt="" className="image-shadow "/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-title">
                                    <span className="wow fadeInUp"
                                          data-wow-delay=".2s"><Trans t={t} i18nKey="getMoreCustomers.subtitle"/></span>
                                    <h2 className="mb-35 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="getMoreCustomers.title"/>
                                    </h2>
                                    <p className="mb-35 wow fadeInUp" data-wow-delay=".6s">
                                        <Trans t={t} i18nKey="getMoreCustomers.text">
                                            Customers experience matter. <b>Allow customers to make reservation in your
                                            restaurant with
                                            our app.</b> Existing users of Reetab will be able to you and book a table
                                            in
                                            your
                                            restaurant directly through the app.
                                        </Trans>
                                    </p>
                                    <HashLink to={'/#features'} className="main-btn btn-hover wow fadeInUp">
                                        <Trans t={t} i18nKey="getMoreCustomers.button"/>
                                    </HashLink>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>


            <section id="features" className="testimonial-section img-bg pb-30 pt-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title mb-60 text-center">
                                <span className="wow fadeInUp" data-wow-delay=".2s">Reetab Business App</span>
                                <h1 className="wow fadeInUp" data-wow-delay=".4s">
                                    <Trans t={t} i18nKey="features.title"/></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section id="add-reservation" className="about-section received-section ">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-4">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <span className="wow fadeInUp" data-wow-delay=".2s"><Trans t={t}
                                                                                               i18nKey="features.addReservation.subtitle"/></span>
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.addReservation.title"/>
                                    </h2>
                                    <p className="wow fadeInUp" data-wow-delay=".6s">
                                        <Trans t={t} i18nKey="features.addReservation.text">
                                            Adding new reservation is <b>easy.</b> Just drag a box to selected table and
                                            time, enter
                                            customer
                                            name and you are done.
                                        </Trans>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <VideoFrame source={IPadVideoUrls.addReservation}/>
                        </div>

                    </div>
                </div>
            </section>
            <section id="add-reservation-multitable" className="about-section received-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.editReservations.title"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <VideoFrame source={IPadVideoUrls.editReservation}/>
                        </div>

                    </div>
                </div>
            </section>
            <section id="add-reservation-multitable" className="about-section received-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.addReservationMulti.title"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <VideoFrame source={IPadVideoUrls.addMultiTableReservation}/>
                        </div>

                    </div>
                </div>
            </section>
            <section id="add-custom-table-model" className="about-section received-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.createTableModel.title"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <VideoFrame source={IPadVideoUrls.addTableModel}/>
                        </div>

                    </div>
                </div>
            </section>
            <section id="add-new-table" className="about-section received-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.addNewTable.title"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <VideoFrame source={IPadVideoUrls.addTable}/>

                        </div>

                    </div>
                </div>
            </section>

            <section id="add-new-space" className="about-section received-section pt-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="delivery-content">
                                <div className="section-title">
                                    <h2 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t}
                                                                                                   i18nKey="features.createSpace.title"/>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <VideoFrame source={IPadVideoUrls.editSpace}/>
                        </div>

                    </div>
                </div>
            </section>

            <section id="pricing" className="testimonial-section img-bg pt-120 pb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-title mb-50 text-center">
                                <h1 className="wow fadeInUp" data-wow-delay=".4s"><Trans t={t} i18nKey="pricing.title"/>
                                </h1>
                            </div>
                        </div>
                    </div>

                    <div className="row testimonial-wrapper justify-content-center row-eq-height">
                        <PricingPlan planName={i18n.t('pricing.plans.basic.title')} pricePerMonth={19}
                                     pricePerPerson={0.25}
                                     tables={10} spaces={1} singleTable={true}
                                     multiTable={false} support={false} multipleOpenHours={false}
                                     buttonText={i18n.t('pricing.plans.basic.button')} freeTrial={true}
                                     invertedButton={true} waitingList={false}/>
                        <PricingPlan planName={i18n.t('pricing.plans.premium.title')} pricePerMonth={49}
                                     pricePerPerson={0.25}
                                     tables={Infinity} spaces={4} singleTable={true}
                                     multiTable={true} support={false} multipleOpenHours={false}
                                     buttonText={i18n.t('pricing.plans.premium.button')} freeTrial={true} waitingList={false}/>
                        <PricingPlan planName={i18n.t('pricing.plans.premiumPlus.title')} pricePerMonth={129}
                                     pricePerPerson={0.25}
                                     tables={Infinity} spaces={Infinity} singleTable={true}
                                     multiTable={true} support={true} multipleOpenHours={true}
                                     buttonText={i18n.t('pricing.plans.premiumPlus.button')} freeTrial={false}
                                     invertedButton={true} waitingList={true}/>


                    </div>
                </div>
            </section>

            <section id="all-plans-include" className="about-section received-section  pb-120">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-lg-6 mb-60">
                            <h4 className="mb-25 wow fadeInUp" data-wow-delay=".4s">
                                <Trans t={t} i18nKey="allPlansInclude.title"/>
                            </h4>
                            <div className="wow fadeInUp" data-wow-delay=".6s">
                                <ul className="pricing-ul">
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.noStartFees"><b>No starting
                                            fees</b></Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.zeroFees"><b>Zero fees</b> for
                                            reservations you add on Reetab Business app</Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.onlineListing"><b>Online</b> listing
                                            and
                                            reservations on Reetab app</Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.webApp">Reetab Business web app
                                            for <b>managing tables</b> and <b>reservations</b>
                                        </Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t}
                                                  i18nKey="allPlansInclude.mobileApp"><b>iOS</b> and <b>Android</b> app
                                            for tablets and mobile phones</Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.customPreferences">Customizable
                                            preferences
                                            for online reservations</Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.bookingWidget">Custom <b>booking
                                            widget</b> for your website</Trans></p>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"/>
                                        <p><Trans t={t} i18nKey="allPlansInclude.floorPlan"><b>Floor
                                            plan</b> editor</Trans></p>
                                    </li>
                                </ul>


                            </div>

                        </div>
                        <div className=" col-lg-6">
                            <MessageForm/>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;
