import FrameImage from "../assets/img/ipad-11-pro-mask-sm-3.png";
import React from "react";

interface VideoFrameProps {
    source: string;
}

const VideoFrame: React.FC<VideoFrameProps> = ({source}) => {
    return (<div style={{position: 'relative'}}>


        <div className="video-ipad-11-frame" data-wow-delay=".5s">
            <video autoPlay playsInline loop muted controls={false}>
                <source src={source} type="video/mp4"/>
                This browser does not display the video tag.
            </video>
        </div>
        <div className="overlay">
            <img src={FrameImage} alt={"frame"}/>
        </div>

    </div>)
}

export default VideoFrame;
