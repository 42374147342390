import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import HttpBackend from "i18next-http-backend";
import {format as formatDate, isDate} from "date-fns";
import {cs, da, de, enGB, es, fr, hr, hu, it, ja, ko, pl, pt, ro, sl, sv, tr, sr} from "date-fns/locale"; // import all locales we need

const locales = {cs, da, de, enGB, es, fr, hr, hu, it, ja, ko, pl, ro, sl, sv, tr, pt, sr}; // used to look up the required locale

// @ts-ignore
i18n.use(HttpBackend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
    format: (value, format: string, lng: string) => {
      if (isDate(value)) {
        // @ts-ignore
        const locale = locales[lng];
        return formatDate(value, format, {locale});
      }
    }
  },
  detection: {
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator'],
    caches: ['localStorage', 'cookie'],
  }
})

export default i18n;
