import React from "react";
import {Trans, useTranslation} from "react-i18next";
import {Urls} from "../constants/urls";
import {Tooltip} from "react-tooltip";

export interface PricingPlanProps {
  planName: string
  pricePerMonth: number
  pricePerPerson: number
  tables: number
  spaces: number
  singleTable?: boolean
  multiTable?: boolean
  support?: boolean
  multipleOpenHours?: boolean
  buttonText: string
  freeTrial?: boolean
  invertedButton?: boolean
  waitingList?: boolean
}

interface PlanItemProps {
  children?: React.ReactNode
  included?: boolean
}

const PlanItem: React.FC<PlanItemProps> = ({children, included = true}): any => {
  return <>
    <li className={included ? "" : "not-included"}>
      <i className={included ? "bi bi-check" : "bi bi-x"}/>
      <p>{children}</p>
    </li>
  </>
}

const PricingPlan: React.FC<PricingPlanProps> = (
  {
    planName,
    pricePerMonth,
    pricePerPerson,
    tables,
    spaces,
    singleTable,
    multiTable,
    support,
    multipleOpenHours,
    freeTrial,
    buttonText,
    invertedButton,
    waitingList,
  }: PricingPlanProps) => {
  const {t, i18n} = useTranslation()

  const feeExplanation = i18n.t('pricing.extra.feeExplanation', {count: pricePerPerson * 2});
  const operatingHoursExplanation = i18n.t('pricing.operatingHoursExplanation');

  return <>
    <div className="col-lg-4 col-md-8 -mt-30 mb-30">
      <div className="single-testimonial shadow d-flex flex-column"
           data-wow-delay=".6s">

        <div className="info">
          <div className="section-title text-center mb-40">
            <h4 className="plan-name">{planName}</h4>
          </div>
        </div>
        <div className="info">
          <div className="section-title mb-40 text-center">
            <h1>{pricePerMonth} €</h1>
            <h4 className="font-weight-light month"><Trans t={t} i18nKey="pricing.perMonth"/></h4>
          </div>
        </div>
        <div className="content">
          <ul className="pricing-ul mb-20">
            <PlanItem>
              <Trans t={t}
                     i18nKey={tables === Infinity ? "pricing.tables.tableCountUnlimited" : "pricing.tables.tableCount"}
                     count={tables}>
                Manage up to <b>{{tables}} tables</b>
              </Trans>
            </PlanItem>
            <PlanItem>
              <Trans t={t}
                     i18nKey={spaces === Infinity ? "pricing.spaces.spaceCountUnlimited" : "pricing.spaces.spaceCount"}
                     count={spaces}>
                Create up to <b>{{spaces}} tables</b>
              </Trans>
            </PlanItem>
            <PlanItem included={singleTable}>
              <Trans t={t} i18nKey="pricing.singleReservation"/>
            </PlanItem>
            <PlanItem included={multiTable}>
              <Trans t={t} i18nKey="pricing.multiReservations">
                Add <b>multi-table</b> reservations
              </Trans>
            </PlanItem>

            <PlanItem included={multipleOpenHours}>
              {multipleOpenHours ? <>
                  <Trans t={t} i18nKey="pricing.operatingHours">
                                <span className="blue-text"
                                      data-tooltip-id={"pricing-extra-fee-tooltip"}
                                      data-tooltip-content={operatingHoursExplanation}
                                >
                                        Multiple
                                </span> <b>operating hours</b></Trans></> :
                <Trans t={t} i18nKey="pricing.operatingHours"><span>Multiple</span> <b>operating
                  hours</b></Trans>}
            </PlanItem>
             <PlanItem included={waitingList}>
              <Trans t={t} i18nKey="pricing.waitingList">
                Manage <b>waitlist</b>
              </Trans>
            </PlanItem>
            <PlanItem included={support}>
              <Trans t={t} i18nKey="pricing.support">
                <b>24/7</b> phone and email <b>support</b>
              </Trans>
            </PlanItem>
            <li className="row mt-25 mb-20 align-items-center">
              <div className="col">
                <hr/>
              </div>
              <div className="col-auto extra-text"><Trans t={t} i18nKey="pricing.extra.title"/></div>
              <div className="col">
                <hr/>
              </div>
            </li>

              <Tooltip className='tooltip-width' id={"pricing-extra-fee-tooltip"}/>
            <li className="extra">
              <i className="bi bi-circle-fill"/>
              <p><Trans t={t} i18nKey="pricing.extra.zeroFees"><b>No extra fees</b> per reservation</Trans></p>
            </li>
            {/*<li className="extra">*/}
            {/*  <i className="bi bi-circle-fill"/>*/}
            {/*  <p>*/}
            {/*    <Trans t={t}*/}
            {/*           i18nKey={"pricing.extra.fee"}*/}
            {/*           count={pricePerPerson}>*/}
            {/*      <span className="blue-text" data-tooltip-id={"pricing-extra-fee-tooltip"}*/}
            {/*            data-tooltip-content={feeExplanation}>Fee </span> of <b>{pricePerPerson} €</b> per*/}
            {/*      cover*/}
            {/*      for online reservations on*/}
            {/*      Reetab network*/}
            {/*    </Trans>*/}
            {/*  </p>*/}
            {/*</li>*/}

            {freeTrial &&
              <li className="extra">
                <i className="bi bi-circle-fill"/>
                <p><Trans t={t} i18nKey="pricing.extra.freeTrial"><b>Free trial</b> for 30 days — no credit card required </Trans></p>
              </li>
            }
          </ul>
        </div>
        <a href={Urls.signup} rel="noreferrer" target="_blank"
           className={invertedButton ? "free-plan-button mt-auto" : "pro-plan-button "}>{buttonText}</a>
      </div>
    </div>
  </>
}

export default PricingPlan
