export const Urls = {
    login: "https://app.reetab.com/app/login",
    signup: "https://app.reetab.com/app/register",
    contactAPI: "https://reetab.com/api/landing_page/contact",
    appStoreReetabBusiness: "https://apps.apple.com/us/app/reetab-business/id1478790179",
    playStoreReetabBusiness: "https://play.google.com/store/apps/details?id=com.reetab.reetabBusiness",
    widgetAPI: {
        getFutureDates: "https://reetab.com/api/widget/v1/reservations/get_future_dates",
        getReservationsInfo: "https://reetab.com/api/widget/v1/reservations/info",
        checkAvailability: "https://reetab.com/api/widget/v1/reservations/check_availability",
        confirmBooking: "https://reetab.com/api/widget/v1/reservations"
    },
    // bookingWidget: "https://business.reetab.com/book"
    bookingWidget: "http://localhost:3000/book"
}

export const DragDropDoneVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-3-720-60.mp4"
export const DoneVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/multitable-browser.mp4"
export const CreateCustomTableModelVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-table-model-browser.mp4"
export const AddNewTableVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/add-table-browser-720.mp4"
export const CreateCustomSpaceVideoSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/browser-spaces.mp4"
export const MultitableIpadSrc = "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/multitable-ipad.mp4"

export const IPadVideoUrls = {
    editSpace: "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/edit-space-ipad.mp4",
    addTable: "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/add-table-ipad.mp4",
    addTableModel:"https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/add-table-model-ipad.mp4",
    addMultiTableReservation: "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/add-multi-table-reservation-ipad.mp4",
    addReservation: "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/add-reservation-ipad.mp4",
    editReservation: "https://reetab.s3.eu-central-1.amazonaws.com/landing-page/videos/ipad-pro-11/edit-reservations-ipad.mp4",
}
