import DeleteAccountDataForm from "../../components/DeleteAccountDataForm";

const DeleteAccountPage = () => {
  return (
    <div className="">
      <div className={"row justify-content-center"}>
        <div className="Data col-lg-6">
          <section id="delete-data" className="terms-section">
            <div className="container">
              <h2>Delete Data Request</h2>
              <p>
                By submitting this form, you can request to delete all your data from our servers. We will send you an
                email
                to confirm your request. Request will be processed in 8 working days.
                Please note that this action is irreversible.
              </p>

              <DeleteAccountDataForm/>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccountPage;
