import React, {useEffect, useState} from "react";
import {format} from 'date-fns';
import ReservationForm from "../components/ReservationForm";
import Logo from "../assets/img/logo/reetab-logo-black.png";
import LogoDark from "../assets/img/logo/reetab-logo-iverted.png";
import {useLocation} from "react-router-dom";
import queryString from 'query-string'
import {Urls} from "../constants/urls";
import {Trans, useTranslation} from "react-i18next";
import {ScrollMenu} from "react-horizontal-scrolling-menu";

interface SelectorButtonProps {
    text: string | number
    mainColor?: string
    selected?: boolean
    darkMode?: boolean
    onClick?: () => null
}


const SelectorButton = ({selected, onClick = () => null, text, darkMode, mainColor}: SelectorButtonProps) => {

    const buttonStyle = {
        background: selected && darkMode && mainColor ? mainColor : undefined,
        borderColor: selected && darkMode && mainColor ? mainColor : undefined,
    }

    let className = darkMode ? "btn btn-md btn-dark btn-dark-2 mr-2 mb-2 border-min booking-select-item" : "btn btn-md btn-light mr-2 mb-2 border-min booking-select-item";
    if (selected) {
        className = darkMode ? "btn btn-md btn-dark btn-dark-2 mr-2 mb-2 border-min booking-select-item booking-select-item-dark-mode-selected" : "btn btn-md btn-dark btn-dark-2 mr-2 mb-2 border-min booking-select-item"
    }

    return (<div className={"button-container"}>
        <button onClick={onClick} className={className} style={buttonStyle}>{text}</button>
    </div>)
}

const TimeOption = ({time, selectedTime, setSelectedTime = () => null, darkMode, mainColor}: any) => {

    return <SelectorButton mainColor={mainColor} text={time} onClick={() => setSelectedTime(time)}
                           selected={selectedTime === time}
                           darkMode={darkMode}/>

}

const GuestOption = ({guests, selectedNumberOfGuests, setSelectedNumberOfGuests, darkMode, mainColor}: any) => {
    return <SelectorButton mainColor={mainColor} text={guests} onClick={() => setSelectedNumberOfGuests(guests)}
                           selected={selectedNumberOfGuests === guests} darkMode={darkMode}/>

}


const Day = ({date, selectedDate, setSelectedDate, closed, mainColor, darkMode}: any) => {
    let boxShadow: string | undefined = "0px 0px 4px 0px rgba(24,30,35,0.15)";
    // let boxShadow = "0px 0px 2px 0px rgba(255,255,255,0.65)";
    let backgroundColor = darkMode ? "#3b4148" : "white";
    let topColor = darkMode ? "rgba(32,34,36,1)" : mainColor;
    let topFontColor = "white";
    let fontColor = darkMode ? "white" : "#212529";

    if (date === selectedDate) {
        topColor = darkMode ? mainColor : "#212529";
    }

    if (closed) {
        backgroundColor = darkMode ? "rgb(25,26,28)" : "white";
        topColor = darkMode ? "rgb(25,26,28)" : "#dee1e5";

        topFontColor = darkMode ? "#515a65" : "white";
        fontColor = darkMode ? "#515a65" : "#dee1e5";
    }

    if (darkMode) {
        boxShadow = closed ? undefined: "0px 0px 6px 0px rgba(0,0,0,0.25)";
    }


    const dateObj = new Date(date)
    const day = format(dateObj, 'd')

    const { i18n} = useTranslation()

    return (
        <button className={"btn btn-sm calendar-day-container"} onClick={() => setSelectedDate(date)}>
            <div
                className={"calendar-day"}
                style={{
                    boxShadow: boxShadow,
                    background: backgroundColor,

                }}>
                <div style={{background: topColor, margin: -1}}>
                    <span style={{
                        fontSize: 13,
                        lineHeight: 1.8,
                        paddingBottom: 1,
                        color: topFontColor,
                        fontWeight: 600
                    }}>{i18n.t('datetime.month', {date: dateObj})}</span>
                </div>
                <span style={{fontSize: 22, paddingTop: 2, paddingBottom: 1, color: fontColor}}>{day}</span>
                <span style={{
                    fontSize: 13,
                    lineHeight: 1,
                    color: fontColor,
                    paddingBottom: 7
                }}>{closed ? i18n.t('datetime.closed') : i18n.t('datetime.dayInWeek', {date: dateObj})}</span>
            </div>
        </button>)
}

interface dateOption {
    hours: string[]
    closed: boolean
    date: string
}

const Book = () => {
    const [selectedTime, setSelectedTime] = useState<string | undefined>(undefined)
    const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined)
    const [selectedNumberOfGuests, setSelectedNumberOfGuests] = useState(0)

    const [isLoading, setIsLoading] = useState(false);
    const [restaurantId, setRestaurantId] = useState("");

    const [isDarkMode, setIsDarkMode] = useState(false);

    const [isAvailable, setIsAvailable] = useState(false);
    const [gotResult, setGotResult] = useState(false);
    // const [durationMinutes, setDurationMinutes] = useState(0);
    const [containerBackground, setContainerBackground] = useState("white");

    const [dateOptions, setDateOptions] = useState<dateOption[]>([]);
    const [timeOptions, setTimeOptions] = useState<string[]>([]);

    const [isSuccess, setIsSuccess] = useState(false);
    const [reservationsInfoRaw, setReservationsInfoRaw] = useState<any>();

    const [confirmReservationStep, setConfirmReservationStep] = useState(false);

    const [guestLimit, setGuestLimit] = useState(100);
    const [guestLimitMessage, setGuestLimitMessage] = useState("");
    const [bookingPolicy, setBookingPolicy] = useState("");

    let guestsOptions = Array(100).fill(null).map((_, i) => i + 1);
    const {t, i18n} = useTranslation()

    useEffect(() => {
        setGotResult(false)
    }, [selectedTime, selectedDate, selectedNumberOfGuests])


    const continueToBooking = () => {
        setConfirmReservationStep(true)
    }

    const goBack = () => {
        setConfirmReservationStep(false)
    }

    const {search} = useLocation()
    const queryValues = queryString.parse(search)

    const mainColor = queryValues.color ? `${queryValues.color}` : 'rgba(255,80,71,1)';
    const flat = queryValues?.flat === '1';

    const getFutureDates = () => {
        async function getRequest() {
            const url = `${Urls.widgetAPI.getFutureDates}?restaurant_id=${restaurantId}`
            const response = await fetch(url, {
                method: 'GET',

            });
            if (response.ok) {
                const data = await response.json()
                if (data) {
                    setDateOptions(data)
                }
            }
        }

        getRequest().then()
    }

    const getInfo = () => {
        async function getRequest() {
            const url = `${Urls.widgetAPI.getReservationsInfo}?restaurant_id=${restaurantId}`
            const response = await fetch(url, {
                method: 'GET',

            });
            if (response.ok) {
                const data = await response.json()
                if (data) {
                    setReservationsInfoRaw(data)
                }
            }
        }

        getRequest().then()
    }

    useEffect(() => {
        setGuestLimitMessage(reservationsInfoRaw?.larger_party_size_message)
        setGuestLimit(reservationsInfoRaw?.availability_check_party_size_limit)
        setBookingPolicy(reservationsInfoRaw?.reservation_policy)
    }, [reservationsInfoRaw])

    const checkAvailability = () => {
        setIsLoading(true)

        async function getRequest() {
            const url = `${Urls.widgetAPI.checkAvailability}?restaurant_id=${restaurantId}&time=${selectedTime}&date=${selectedDate}&persons=${selectedNumberOfGuests}`
            const response = await fetch(url, {
                method: 'GET',
            });
            if (response.ok) {
                const data = await response.json()
                setTimeout(() => {
                    setGotResult(true)
                    setIsAvailable(data.available)
                    // setDurationMinutes(data.duration_in_minutes)
                    setIsLoading(false)
                }, 2000)
            } else {
                setTimeout(() => {
                    setGotResult(true)
                    setIsAvailable(false)
                    setIsLoading(false)
                }, 2000)
            }
        }

        getRequest().then();
    }


    useEffect(() => {
        if (queryValues?.restaurantId) {
            setRestaurantId(`${queryValues.restaurantId}`)
        }
        // if (queryValues?.guestLimit) {
        //     const limit = parseInt(`${queryValues.guestLimit}`, 10);
        //     setGuestLimit(limit);
        // }
        //
        // if (queryValues?.guestLimitMessage) {
        //     const message = `${queryValues.guestLimitMessage}`;
        //     setGuestLimitMessage(message);
        // }

        if (queryValues?.darkMode === '1') {
            setIsDarkMode(true);
            setContainerBackground("rgba(23,26,30,0.94)");
        } else {
            setIsDarkMode(false);
            setContainerBackground("rgba(255,255,255,0.99)");
        }
        if (queryValues?.flat === '1') {
            setContainerBackground("transparent");

        }
        // if (queryValues?.backgroundColor) {
        //     // setContainerBackground(`${queryValues.backgroundColor}`);
        // }


    }, [queryValues])

    useEffect(() => {
        if (restaurantId) {
            getFutureDates()
            getInfo()
        }
    }, [restaurantId, getFutureDates, getInfo])

    useEffect(() => {
        const dateOption = dateOptions.find(x => x['date'] === selectedDate);
        if (dateOption) {
            const hours: string[] = dateOption['hours']
            setTimeOptions(hours)
            if (selectedTime && !hours.includes(selectedTime)) {
                setSelectedTime(undefined)
            }
        }
    }, [selectedDate, dateOptions, selectedTime])

    const dateDate = selectedDate ? new Date(selectedDate) : new Date();

    const dynamicTextStyle = {
        color: isDarkMode ? "white" : '#253b4d',
    }

    if (guestsOptions.length === 0 || dateOptions.length === 0) {
        return <div><p>loading...</p></div>
    }

    return <div className={!flat ? "shadow-sm" : ""}
                style={{
                    backgroundColor: "white",
                    borderRadius: 6,
                    overflow: "hidden",
                    margin: !flat ? 4 : 0,
                    paddingTop: 6,
                    paddingLeft: !flat ? 16 : 0,
                    paddingRight: !flat ? 16 : 0,
                    paddingBottom: 16,
                    // background: isDarkMode ? "rgba(23,26,30,1)" : 'transparent'
                    background: containerBackground
                    // background: 'rgba(255, 255, 255,0.5)'
                }}>
        <div>
            <div style={{
                flexDirection: "row",
                display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 20,
                paddingTop: 10,
            }}>
                <img src={isDarkMode ? LogoDark : Logo} style={{height: 24, width: "auto"}} alt={"Reetab Logo"}/>
                <h6 style={{
                    fontSize: 16,
                    fontWeight: 600,
                    // color: "#323234",
                    ...dynamicTextStyle
                }}>{i18n.t('bookingWidget.bookATable')}</h6>
            </div>
            <div className="row">
                {!confirmReservationStep &&
                <div className="col-lg-12">
                    <div className={"booking-item-container"}>
                        <h6 className={"widget-item-title"}
                            style={{...dynamicTextStyle}}>{i18n.t('bookingWidget.date')}</h6>
                        <ScrollMenu wrapperClassName={'scroll-container'}>
                            {/*<ul className={"scroll-container scroll-container-calendar"}>*/}


                            {dateOptions?.map((x, key) => {
                                return (<Day key={`${key}`} date={x.date} closed={x.closed} selectedDate={selectedDate}
                                             setSelectedDate={setSelectedDate} mainColor={mainColor}
                                             darkMode={isDarkMode}/>);
                            })
                            }
                            {/*</ul>*/}
                        </ScrollMenu>
                    </div>
                    <div className={"booking-item-container"}>
                        <h6 style={{...dynamicTextStyle}}
                            className={"widget-item-title"}>{i18n.t('bookingWidget.time')}</h6>
                        <ScrollMenu wrapperClassName={'scroll-container'}>
                            <div style={{display: 'flex'}}>


                                {selectedDate ? timeOptions.map((x, key) => {
                                        return (<TimeOption key={`${key}`} time={x} selectedTime={selectedTime}
                                                            setSelectedTime={setSelectedTime} darkMode={isDarkMode}
                                                            mainColor={mainColor}/>);
                                    }) :
                                    <TimeOption time={"12:00"} selectedTime={selectedTime}
                                                setSelectedTime={setSelectedTime} darkMode={isDarkMode}
                                                mainColor={mainColor}/>
                                }
                                {selectedDate && timeOptions.length === 0 &&
                                <TimeOption time={i18n.t('bookingWidget.noTimeOptions')} selectedTime={selectedTime}
                                            darkMode={isDarkMode}
                                            mainColor={mainColor}/>}
                            </div>
                        </ScrollMenu>
                    </div>

                    <div className={"booking-item-container"}>
                        <h6 style={{...dynamicTextStyle}}
                            className={"widget-item-title"}>{i18n.t('bookingWidget.guests')}</h6>

                        <ScrollMenu wrapperClassName={'scroll-container'}>
                            <div style={{display: 'flex'}}>


                                {guestsOptions.map((x, key) => {
                                    return (<GuestOption key={`${key}`} guests={x}
                                                         selectedNumberOfGuests={selectedNumberOfGuests}
                                                         setSelectedNumberOfGuests={setSelectedNumberOfGuests}
                                                         darkMode={isDarkMode} mainColor={mainColor}/>);
                                })
                                }
                            </div>
                        </ScrollMenu>
                    </div>

                    {!gotResult && selectedNumberOfGuests <= guestLimit &&
                    <button
                        onClick={() => checkAvailability()}
                        disabled={isLoading || !selectedNumberOfGuests || !selectedDate || !selectedTime}
                        className={"btn btn-md btn-dark btn-dark-2 mt-1 border-min btn-block booking-button"}
                        style={{background: mainColor, borderColor: mainColor}}
                    >
                        {!isLoading ? i18n.t('bookingWidget.checkAvailability') : i18n.t('bookingWidget.checkingAvailability')}
                    </button>
                    }
                    {!gotResult && selectedNumberOfGuests > guestLimit &&
                    <p style={{...dynamicTextStyle}}
                       className={"mt-1 reservation-text"}>{guestLimitMessage}</p>}
                    {gotResult && !isAvailable &&
                    <p style={{...dynamicTextStyle}}
                       className={"mt-1 reservation-text"}>{i18n.t('bookingWidget.notAvailableText')}</p>
                    }
                    {gotResult && isAvailable && !confirmReservationStep &&
                    <>
                        <p style={{...dynamicTextStyle}}
                           className={"mt-1 reservation-text"}>{i18n.t('bookingWidget.isAvailableText')}</p>
                        <button
                            onClick={() => continueToBooking()}
                            className={"btn btn-md btn-dark btn-dark-2 mt-3 border-min btn-block booking-button"}
                        >
                            {i18n.t('bookingWidget.continueToBooking')}
                        </button>
                    </>
                    }

                </div>
                }
                {confirmReservationStep &&
                <div className="col-lg-12">
                    <p style={{...dynamicTextStyle}} className={"mb-2 reservation-text mt-0"}>
                        <Trans t={t} i18nKey="bookingWidget.BookingOverviewText" count={selectedNumberOfGuests}>
                            Booking
                            for <b>{{selectedNumberOfGuests}} guests</b> on <b>{{dateDate}}</b> at <b>{{selectedTime}}</b>
                        </Trans>
                    </p>
                    <ReservationForm restaurantId={restaurantId} date={selectedDate} time={selectedTime}
                                     guests={`${selectedNumberOfGuests}`} onSuccess={() => setIsSuccess(true)}
                                     darkMode={isDarkMode} bookingPolicy={bookingPolicy}/>
                    {!isSuccess &&
                    <button className={"btn btn-sm btn-block mt-2"} style={dynamicTextStyle}
                            onClick={() => goBack()}>{i18n.t('bookingWidget.editReservation')}</button>}
                </div>
                }
            </div>

        </div>
    </div>
}

export default Book
