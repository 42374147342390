import BottomLogo from "../assets/img/logo/reetab-logo-white.png";
import AppStoreImage from "../assets/img/footer/353410.svg";
import GooglePlayImage from "../assets/img/footer/en_badge_web_generic.png";
import React from "react";
import {HashLink} from 'react-router-hash-link';
import {Urls} from "../constants/urls";
// @ts-ignore
import {Trans, useTranslation} from "react-i18next";

export const languages = [
  {
    short: 'en',
    nativeName: 'English',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/gb.png" alt={""}/>
  },
  {
    short: 'de',
    nativeName: 'Deutsch',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/de.png" alt={""}/>
  },

  {
    short: 'it',
    nativeName: 'Italiano',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/it.png" alt={""}/>
  },
  {
    short: 'es',
    nativeName: 'Español',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/es.png" alt={""}/>
  },
  {
    short: 'pt',
    nativeName: 'Português',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/pt.png" alt={""}/>
  },
  {
    short: 'fr',
    nativeName: 'Français',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/fr.png" alt={""}/>
  },
  {
    short: 'pl',
    nativeName: 'Polski',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/pl.png" alt={""}/>
  },
  {
    short: 'hu',
    nativeName: 'Magyar',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/hu.png" alt={""}/>
  },
  {
    short: 'sv',
    nativeName: 'Svenska',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/se.png" alt={""}/>
  },
  {
    short: 'cs',
    nativeName: 'Čeština',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/cz.png" alt={""}/>
  },
  {
    short: 'da',
    nativeName: 'Dansk',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/dk.png" alt={""}/>
  },
  {
    short: 'sl',
    nativeName: 'Slovenščina',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/si.png" alt={""}/>
  },
  {
    short: 'hr',
    nativeName: 'Hrvatski',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/hr.png" alt={""}/>
  },
  {
    short: 'sr',
    nativeName: 'Српски',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/rs.png" alt={""}/>
  },
  {
    short: 'ro',
    nativeName: 'Română',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/ro.png" alt={""}/>
  },
  {
    short: 'tr',
    nativeName: 'Türkçe',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/tr.png" alt={""}/>
  },
  {
    short: 'ko',
    nativeName: '한국어',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/kr.png" alt={""}/>
  },
  {
    short: 'ja',
    nativeName: '日本語',
    flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/jp.png" alt={""}/>
  },

];

const LanguageSelector = () => {
  const {t, i18n} = useTranslation()

  const selectedLanguage = languages.find(language => {
    return language.short === i18n.language.substring(0, 2)
  })
  const selectedFlag = selectedLanguage ? selectedLanguage.flag : languages[0].flag

  // @ts-ignore
  return <div id="language-selector" className="btn-group dropend language-selector mt-1">
    <button className="btn btn-sm btn-secondary  dropdown-toggle" type="button"
            id="dropdownMenuButton1" data-bs-toggle="dropdown"
            aria-expanded="false">
      <Trans t={t} i18nKey="menu.language"/> {selectedFlag}

    </button>
    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
      {languages.map((language) => (
        <li key={language.short}>
          <button
            className="dropdown-item"
            type="submit" onClick={() => i18n.changeLanguage(language.short)}>
            {language.flag} {language.nativeName}
          </button>
        </li>
      ))}
    </ul>
  </div>
}

const Footer = () => {
  const {t} = useTranslation()

  return (<>
    <footer id="footer" className="footer pt-100 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget wow fadeInUp" data-wow-delay=".2s">
              <div className="logo">
                <HashLink to={'/#'} className={"bottom-logo"}><img src={BottomLogo}
                                                                   alt="Logo"/></HashLink>
              </div>
              <div className="download-btns">
                <a href={Urls.appStoreReetabBusiness} target="_blank" rel={"noreferrer"}>
                  <img src={AppStoreImage} alt="app store"
                       className={"app-store-icon"}/></a>
                <a href={Urls.playStoreReetabBusiness} target="_blank" rel={"noreferrer"}>
                  <img src={GooglePlayImage} alt="google play"
                       className={"app-store-icon"}/></a>
              </div>

            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="footer-widget wow fadeInUp" data-wow-delay=".4s">
              <h3><Trans t={t} i18nKey="menu.aboutUs"/></h3>
              <ul className="links">
                <li>
                  <HashLink to={'/#'}><Trans t={t} i18nKey="menu.home"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/#why'}><Trans t={t} i18nKey="menu.whyReetab"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/#features'}><Trans t={t} i18nKey="menu.features"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/#pricing'}><Trans t={t} i18nKey="menu.pricing"/></HashLink>
                </li>

              </ul>
            </div>
          </div>


          <div className="col-lg-3 col-md-6">
            <div className="footer-widget wow fadeInUp" data-wow-delay=".8s">
              <h3><Trans t={t} i18nKey="menu.about"/></h3>
              <ul className="links">
                <li>
                  <HashLink to={'/terms#terms'}><Trans t={t} i18nKey="menu.terms"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/privacy#privacy'}><Trans t={t} i18nKey="menu.privacy"/></HashLink>
                </li>
                <li>
                  <a href={Urls.signup} target="_blank" rel={"noreferrer"}><Trans t={t} i18nKey="menu.signUp"/></a>
                </li>
                <li>
                  <a href={Urls.login} target="_blank" rel={"noreferrer"}><Trans t={t} i18nKey="menu.login"/></a>
                </li>

              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-widget wow fadeInUp" data-wow-delay="1s">
              <h3><Trans t={t} i18nKey="menu.support"/></h3>
              <ul className="links">
                <li>
                  <LanguageSelector/>
                </li>
                <li>
                  <HashLink to={'/#message'}><Trans t={t} i18nKey="menu.openTicket"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/widgets#'}><Trans t={t} i18nKey="menu.widgets"/></HashLink>
                </li>
                <li>
                  <HashLink to={'/badges#'}><Trans t={t} i18nKey="menu.badges"/></HashLink>
                </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>)
}

export default Footer
