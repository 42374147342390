import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {Urls} from "../constants/urls";

const MessageForm = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [restaurantName, setRestaurantName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [isPending, setIsPending] = useState(false)
    const [isDone, setIsDone] = useState(false)
    const [isError, setIsError] = useState(false)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formData = {firstName, lastName, restaurantName, email, message}

        setIsPending(true)

        fetch(Urls.contactAPI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        }).then(res => {
            if (!res.ok) {
                setIsError(true)
            }
            setIsDone(true)
        })
        console.log(formData)
    }

    const {t, i18n} = useTranslation()


    return <section id="message">
        <h4 className="mb-25 wow fadeInUp" data-wow-delay=".4s"><Trans t={t} i18nKey="messageForm.title"/></h4>
        <div className="wow fadeInUp" data-wow-delay=".6s">
            <form onSubmit={handleSubmit}>
                <div className="row form-group mb-2">
                    <div className="col">
                        <label htmlFor="name" className="mb-1"><Trans t={t} i18nKey="messageForm.firstName"/></label>
                        <input disabled={isDone} required type="text" className="form-control input-x"
                               placeholder={i18n.t('messageForm.firstName')}
                               id="name" value={firstName} onChange={e => setFirstName(e.target.value)}/>
                    </div>
                    <div className="col">
                        <label htmlFor="last-name" className="mb-1"><Trans t={t}
                                                                           i18nKey="messageForm.lastName"/></label>
                        <input disabled={isDone} required type="text" className="form-control input-x"
                               placeholder={i18n.t('messageForm.lastName')}
                               id="last-name" value={lastName} onChange={e => setLastName(e.target.value)}/>
                    </div>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="restaurant-name" className="mb-1"><Trans t={t}
                                                                             i18nKey="messageForm.restaurantName"/></label>
                    <input disabled={isDone} required type="text" className="form-control input-x" id="restaurant-name"
                           placeholder={i18n.t('messageForm.restaurantName')} value={restaurantName}
                           onChange={e => setRestaurantName(e.target.value)}/>
                </div>
                <div className="form-group mb-2">
                    <label htmlFor="email" className="mb-1"><Trans t={t} i18nKey="messageForm.email"/></label>
                    <input disabled={isDone} required type="email" className="form-control input-x" id="email"
                           placeholder="email@example.com" value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="form-group mb-4">
                    <label htmlFor="message" className="mb-1"><Trans t={t} i18nKey="messageForm.message"/></label>
                    <textarea disabled={isDone} required className="form-control input-x" id="message" rows={5}
                              placeholder={i18n.t('messageForm.message')}
                              value={message}
                              onChange={e => setMessage(e.target.value)}/>
                </div>
                {!isDone && !isPending &&
                <button type="submit" className="send-button"><Trans t={t} i18nKey="messageForm.sendMessage"/></button>}
                {!isDone && isPending &&
                <button className="send-button" disabled><Trans t={t} i18nKey="messageForm.sending"/></button>}
                {isDone && !isError && <>
                    <p><b><Trans t={t} i18nKey="messageForm.successTitle"/></b></p>
                    <p><Trans t={t} i18nKey="messageForm.successBody"/></p>
                </>}
                {isDone && isError && <>
                    <p><b><Trans t={t} i18nKey="messageForm.successTitle"/></b></p>
                    <p><Trans t={t} i18nKey="messageForm.successBody"/></p>
                </>}
            </form>
        </div>
    </section>
}

export default MessageForm
