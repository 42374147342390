import React, {useEffect, useMemo, useState} from "react";
import {ChromePicker, RGBColor} from 'react-color';
import {Urls} from "../constants/urls";
import  queryString from "query-string";
import {languages} from "../components/Footer";

const WIDGET_HEIGHT = 700;
// const languages = [
//   {
//     short: 'en',
//     nativeName: 'English',
//     flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/gb.png" alt={""}/>
//   },
//   {
//     short: 'de',
//     nativeName: 'Deutsch',
//     flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/de.png" alt={""}/>
//   },
//   {
//     short: 'sl',
//     nativeName: 'Slovenščina',
//     flag: <img width="20" height="15" className="flag" src="https://flagcdn.com/20x15/si.png" alt={""}/>
//   },
// ];

const WidgetCreator = () => {
  // const [htmlCode, setHtmlCode] = useState("")
  const [colorEncoded, setColorEncoded] = useState("")
  // const [backgroundColorEncoded, setBackgroundEncoded] = useState("")

  const [width, setWidth] = useState('100%')
  const [restaurantId, setRestaurantId] = useState("")
  const [color, setColor] = useState<RGBColor>({r: 250, g: 80, b: 71, a: 1})
  const [language, setLanguage] = useState("")
  const [darkMode, setDarkMode] = useState(0)
  const [flat, setFlat] = useState(0)
  // const [backgroundColor, setBackgroundColor] = useState<RGBColor>({r: 255, g: 255, b: 255, a: 1})


  const onColorSelect = (color: any) => {
    setColor(color.rgb);
  }

  // const onBackgroundColorSelect = (color: any) => {
  //     setBackgroundColor(color.rgb);
  // }


  const encodeRGBColorURI = (rgbColor: RGBColor) => {
    return `rgba(${rgbColor.r}, ${rgbColor.g}, ${rgbColor.b}, ${rgbColor.a})`;
    // return encodeURIComponent(colorString);
  }

  useEffect(() => {
    setColorEncoded(encodeRGBColorURI(color));
  }, [color])

  // useEffect(() => {
  //     setBackgroundEncoded(encodeRGBColorURI(backgroundColor));
  // }, [backgroundColor])

  // const [iframeUrl, setIframeUrl] = useState("")


  const iframeUrl = useMemo(() => {
    return `${Urls.bookingWidget}?${queryString.stringify({
      restaurantId: 42,
      color: colorEncoded,
      lng: language,
      flat: flat,
      darkMode: darkMode,
    })}`
  }, [colorEncoded, language, flat, darkMode])

  const htmlCode = useMemo(() => {
    const options = {
      restaurantId: restaurantId,
      color: colorEncoded,
      lng: language,
      flat: flat,
      darkMode: darkMode,
    }

    const query = queryString.stringify(options);

    return `<iframe src="${Urls.bookingWidget}?${query}" title="Reetab Booking" width="${width}" height="${WIDGET_HEIGHT}"  allowtransparency="true" frameborder="0" style="border:0"></iframe>`;
  }, [restaurantId, colorEncoded, language, flat, darkMode, width])


  return <div className="WidgetCreator">
    <section id="privacy" className="terms-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h2>Add booking widget to your website</h2>
            <p>Select your preferred <b>widget size</b> and <b>restaurant
              ID</b> to generate html code. You can find your restaurant ID in the Reetab Business app
              under profile section in settings.
              Optionally you can switch to dark mode, change default theme color and language.

            </p>

          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-6">

            <div className={"mb-2"}>
              <h5 className={"mb-2"}>Restaurant ID</h5>
              <div className={"col-6"}>
                <input className="form-control input-x" id="restaurantId"
                       placeholder="Enter your restaurant id" value={restaurantId}
                       onChange={e => setRestaurantId(e.target.value)}/>

              </div>
            </div>
            <div className={"mb-2"}>
              <h5 className={"mb-2 mt-3"}>Size</h5>

              <button onClick={() => setWidth('100%')}
                      className={width === '100%' ? "btn btn-md btn-dark mr-2  mb-2" : "btn btn-md btn-light mr-2  mb-2"}>Full
                width
              </button>
              <button onClick={() => setWidth('300')}
                      className={width === '300' ? "btn btn-md btn-dark mr-2  mb-2" : "btn btn-md btn-light mr-2  mb-2"}
              >
                Small
              </button>
            </div>

            <div className={"mb-2"}>
              <h5 className={"mb-2"}>Language</h5>
              <button onClick={() => setLanguage('')}
                      className={language === '' ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}
              >
                Auto
              </button>
              {languages.map((lng) => (
                <button
                  className={language === lng.short ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}
                  key={lng.short}
                  type="submit" onClick={() => setLanguage(lng.short)}>
                  {lng.flag} {lng.nativeName}
                </button>
              ))}
            </div>
            <div className={"mb-2"}>
              <h5 className={"mb-2"}>Theme</h5>

              <button onClick={() => setDarkMode(0)}
                      className={darkMode === 0 ? "btn btn-md btn-dark mr-2  mb-2" : "btn btn-md btn-light mr-2  mb-2"}>Light
              </button>
              <button onClick={() => setDarkMode(1)}
                      className={darkMode === 1 ? "btn btn-md btn-dark mr-2  mb-2" : "btn btn-md btn-light mr-2  mb-2"}
              >
                Dark
              </button>
            </div>
            {/*<div className={"mb-2"}>*/}
            {/*    <h5 className={"mb-2"}>Shadow</h5>*/}

            {/*    <button onClick={() => setShadow(1)}*/}
            {/*            className={shadow ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}>Shadow*/}
            {/*    </button>*/}
            {/*    <button onClick={() => setShadow(0)}*/}
            {/*            className={!shadow ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}*/}
            {/*    >*/}
            {/*        No shadow*/}
            {/*    </button>*/}
            {/*</div>*/}

            <div className={"mb-2"}>
              <h5 className={"mb-2"}>Mode</h5>

              <button onClick={() => setFlat(0)}
                      className={!flat ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}>Widget
              </button>
              <button onClick={() => setFlat(1)}
                      className={flat ? "btn btn-md btn-dark mr-2 mb-2" : "btn btn-md btn-light mr-2 mb-2"}
              >
                Flat
              </button>
            </div>

            <div className={"mb-2"}>
              <h5 className={"mb-3"}>Theme Color</h5>

              {/*<div className={'mb-3'}>*/}
              {/*    <TwitterPicker color={color} onChangeComplete={onColorSelect} width={"225px"} styles={{*/}
              {/*        default:*/}
              {/*            {*/}
              {/*                hash: {*/}
              {/*                    display: 'none',*/}
              {/*                },*/}
              {/*                input: {*/}
              {/*                    display: 'none'*/}
              {/*                }*/}
              {/*            }*/}
              {/*    }}*/}
              {/*                   colors={['#ff5047', '#00D084', '#FF6900', '#8ED1FC', '#0693E3', '#F78DA7', '#206839']}/>*/}
              {/*</div>*/}
              <ChromePicker color={color} onChangeComplete={onColorSelect}/>
              {/*<ChromePicker color={color} onChangeComplete={onColorSelect}/>*/}
            </div>
            <div className={"mb-3 mt-4"}>
              <h5 className={"mb-2"}>Booking Policy and Settings</h5>
              <p>To inform guests about your booking policy, you can edit restaurant's policy
                through Reetab business app in Settings/Reservations. Policy is displayed in
                second step before a guest confirms the reservation.
              </p>
              <p>
                You can also edit info about alternative ways for arranging a reservation in your
                restaurant for a larger party size.
              </p>
            </div>
            {/*<div className={"mb-4"}>*/}
            {/*    <h5 className={"mb-3"}>Background transparency</h5>*/}
            {/*    <AlphaPicker color={backgroundColor} onChangeComplete={onBackgroundColorSelect}/>*/}
            {/*</div>*/}


            {/*<div className={"mb-4"}>*/}
            {/*    <h5 className={"mb-2"}>Biggest party size for auto availability check</h5>*/}
            {/*    <div className={"col-6"}>*/}
            {/*        <div className="input-group">*/}
            {/*            <input className="form-control input-x" id="guestLimit"*/}
            {/*                   placeholder="ex. 6" value={guestLimit}*/}
            {/*                   onChange={e => setGuestLimit(e.target.value)}/>*/}
            {/*            <div className="input-group-text">guests</div>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={"mb-4"}>*/}
            {/*    <h5 className={"mb-2"}>Message when more guests are selected</h5>*/}
            {/*    <div className={"col-6  mb-5"}>*/}
            {/*        <textarea className="form-control input-x" id="guestLimitMessage" rows={4}*/}
            {/*                  placeholder="Ex. For reservations over 6 guests, contact us at +12 456 789 or send us an email to booking@restaurant.com"*/}
            {/*                  value={guestLimitMessage}*/}
            {/*                  onChange={e => setGuestLimitMessage(e.target.value)}/>*/}

            {/*    </div>*/}
            {/*</div>*/}
          </div>
          <div className="col-lg-6">
            <h4>HTML code</h4>


            <p>Include this HTML code to add booking widget on your website</p>
            <textarea required className="form-control input-x mb-20" id="message" rows={5}
                      value={htmlCode}/>
            <h4 className={"mb-2"}>Preview</h4>
            <iframe
              src={iframeUrl}
              title="Reetab Booking"
              width="100%"
              height={WIDGET_HEIGHT} frameBorder="0"
              style={{width: width === "300" ? 300 : undefined}}
            />

            <h4 className={"mb-2"}>Preview with background image</h4>
            <div className={"preview-bg-image"}>
              <iframe
                src={iframeUrl}
                title="Reetab Booking"
                width="100%"
                height={WIDGET_HEIGHT} frameBorder="0"
                style={{width: width === "300" ? 300 : undefined}}
              />
            </div>
          </div>
        </div>

      </div>
    </section>
  </div>
}

export default WidgetCreator
