import React, {useState} from "react";
import {Urls} from "../constants/urls";

const DeleteAccountDataForm = () => {
  const [email, setEmail] = useState("")
  const [isPending, setIsPending] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const [isError, setIsError] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = {
      firstName: "Delete", lastName: "Account", restaurantName: "None", email, message: "Delete my account"}

    setIsPending(true)

    fetch(Urls.contactAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    }).then(res => {
      if (!res.ok) {
        setIsError(true)
      }
      setIsDone(true)
    })
  }

  return <section id="message">
    {/*<h5 className="wow fadeInUp mb-1" data-wow-delay=".4s">Enter your account details</h5>*/}
    <div className="wow fadeInUp" data-wow-delay=".6s">
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="email" className="mb-1">Account email or username</label>
          <input disabled={isDone} required type="email" className="form-control input-x" id="email"
                 placeholder="email@example.com" value={email} onChange={e => setEmail(e.target.value)}/>
        </div>
        {!isDone && !isPending &&
          <button type="submit" className="send-button">Submit data deletion request</button>}
        {!isDone && isPending &&
          <button className="send-button" disabled>Submitting...</button>}
        {isDone && !isError && <>
          <p><b>Request was sent</b></p>
          <p>We will review your request for data deletion and process it in 8 working days. You will receive an email
            asking you to confirm account deletion.</p>
        </>}
        {isDone && isError && <>
          <p><b>Something went wrong</b></p>
          <p>Your request could not be processed</p>
        </>}
      </form>
    </div>
  </section>
}

export default DeleteAccountDataForm

