import React from 'react';
import './App.css';
import NavbarX from './components/NavbarX';
import Footer from "./components/Footer";

import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./routes/Home";
import Terms from "./routes/Terms";
import Privacy from "./routes/Privacy";
import Badges from "./routes/Badges";
import Book from "./routes/Book";
import WidgetCreator from "./routes/WidgetCreator";
import DeleteAccountPage from "./routes/app/DeleteData";


function App() {
  return (
    <Router>
      <div className="App">
        {/*<Preloader/>*/}

        <Switch>
          <Route path={"/book"}>
            <Book/>
          </Route>
          <>
            <header className="header">
              <NavbarX/>
            </header>
            <Route exact path={"/"}>
              <Home/>
            </Route>
            <Route path={"/terms"}>
              <Terms/>
            </Route>
            <Route path={"/privacy"}>
              <Privacy/>
            </Route>
            <Route path={"/badges"}>
              <Badges/>
            </Route>
            <Route path={"/widgets"}>
              <WidgetCreator/>
            </Route>
            <Route path={"/app/data"}>
              <DeleteAccountPage/>
            </Route>
            <Footer/>

          </>

        </Switch>
      </div>
    </Router>
  );
}

export default App;
